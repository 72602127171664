function domReady(fn) {
    // If we're early to the party
    document.addEventListener("DOMContentLoaded", fn);
    // If late; I mean on time.
    if (document.readyState === "interactive" || document.readyState === "complete") {
        fn();
    }
}

function menuOffset() {
    let offsetTop = window.scrollY;
    let headerElem = document.querySelector('.header');
    if (headerElem) {
        let headerInner = headerElem.querySelector('.header__inner');
        if (headerInner) {
            if (offsetTop > 0) {
                headerInner.classList.add('fixed');
            } else {
                if (!headerInner.classList.contains('show')) {
                    headerInner.classList.remove('fixed');
                }
            }
        }
    }
}

function toggleMenu() {
    let headerNav = document.querySelector('.header__inner');
    if (headerNav) {
        let offsetTop = window.scrollY;
        if (offsetTop < 1) {
            if (!headerNav.classList.contains('show')) {
                headerNav.classList.add('fixed');
            } else {
                headerNav.classList.remove('fixed');
            }
        }
        headerNav.classList.toggle('show');
    }
}

domReady(() => {
    menuOffset();
    window.addEventListener('scroll', (e) => {
        menuOffset();
    });

    let burger = document.querySelector('.burger__menu');
    if (burger) {
        burger.addEventListener('click', (e) => {
            toggleMenu();
        });
    }

    let anchorLinks = Array.from(document.querySelectorAll('.anchor__link'));
    if (anchorLinks.length) {
        let headerElem = document.querySelector('.header');
        anchorLinks.forEach(item => {
            item.addEventListener('click', (e) => {
                e.preventDefault();
                let sectionID = item.getAttribute('href');
                let section = document.querySelector(sectionID);
                if (headerElem && section) {
                    let offsetTop = window.scrollY + section.getBoundingClientRect().top;
                    let offset = offsetTop - headerElem.offsetHeight;

                    window.scrollTo({
                        top: offset,
                        behavior: "smooth"
                    });
                }
            })
        })
    }

    let form = document.querySelector('.feedback__section-inner-form');
    if (form) {
        form.addEventListener('submit', e => {
            e.preventDefault();

            let formElement = e.target;
            sendForm(formElement);

            return false;
        })
    }
});

ymaps.ready(init);

function init() {
    // Создание карты.
    let map = new ymaps.Map("map", {
        // Координаты центра карты.
        // Порядок по умолчанию: «широта, долгота».
        // Чтобы не определять координаты центра карты вручную,
        // воспользуйтесь инструментом Определение координат.
        center: [41.354350, 69.223358],
        // Уровень масштабирования. Допустимые значения:
        // от 0 (весь мир) до 19.
        zoom: 17
    });

    let mark = new ymaps.Placemark(map.getCenter());

    map.controls.remove('geolocationControl');
    map.controls.remove('searchControl');
    map.controls.remove('trafficControl');
    map.controls.remove('typeSelector');
    map.controls.remove('fullscreenControl');
    map.controls.remove('rulerControl');
    map.behaviors.disable(['scrollZoom']);

    map.geoObjects.add(mark);
}

function sendForm(formElement) {
    let button = formElement.querySelector('.btn');
    let form = new FormData(formElement);
    button.disabled = true;

    const toast = new Toasty({
        enableSounds: false,
        progressBar: false,
    });
    axios({
        method: 'POST',
        url: 'http://dev.itc.uz/api/front/feedback',
        data: form,
    }).then(({data}) => {
        formElement.reset();
        toast.success(data.message);
    }).catch(({response}) => {
        if(response && response.data && response.data.errors) {
            let errors = response.data.errors;
            for(let i in errors) {
                let item = errors[i];
                if(item && item.length) {
                    toast.error(item[0]);
                }
            }
        }
    }).finally(() => {
        button.removeAttribute('disabled');
    });
}
